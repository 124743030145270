.cs_site_header.cs_style1.cs_active_sticky {
  background-color: #fff;
}
.cs_site_header.cs_style1.cs_white_color.cs_active_sticky {
  background-color: #274760;
}
.cs_accent_bg .cs_social_links_wrap h2,
.cs_site_header.cs_style1.cs_white_color.cs_active_sticky .cs_toolbox {
  color: #fff;
}
.cs_contact_widget i {
  height: 20px;
  width: 20px;
  margin-top: 2px;
  color: #fff;
  padding: 4px;
}
.cs_video_open {
  cursor: pointer;
}
.cs_hero.cs_style_1 {
  .cs_text_btn_2,
  .cs_hero_title,
  .cs_hero_subtitle {
    position: relative;
    z-index: 1;
  }

  .cs_hero_text {
    position: initial;
    z-index: initial;
  }
}
.cs_accordian_body_wrap {
  overflow: hidden;
  transition: all 0.4s ease;
}
.cs_tabs.cs_style1 .cs_tab_links .cs_tab_link_in {
  cursor: pointer;
}
.cs_rating {
  i {
    display: flex;
  }
}
.cs_shape_wrap .cs_shape_2 {
  top: -3%;
}
.cs_cs_slider_navigation_1 {
  .cs_slider_next,
  .cs_slider_prev {
    transition: all 0.4s ease;
    cursor: pointer;
    border-radius: 10px;
    padding: 5px;
    height: 60px;
    transform: translateY(-50%);
    background-color: #fff;
    width: 70px;
    box-shadow: 0px 4px 21px 1px rgba(48, 123, 196, 0.1);
    position: absolute;
    top: 50%;
    display: flex !important;
    z-index: 1;
    @media (min-width: 991px) {
      opacity: 0;
    }
  }
  .cs_slider_next {
    right: -35px;
  }
  .cs_slider_prev {
    left: -35px;
  }
  &:hover {
    .cs_slider_next,
    .cs_slider_prev {
      opacity: 1;
    }
  }
}
.cs_cs_slider_navigation_2 {
  .cs_slider_next,
  .cs_slider_prev {
    height: 40px;
    width: 40px;
    border: 2px solid $accent;
    border-radius: 50%;
    padding: 8px;
    transition: all 0.4s ease;
    cursor: pointer;
    position: absolute;
    display: flex !important;
    bottom: 0;
  }
  .cs_slider_next {
    right: 0;
  }
  .cs_slider_prev {
    right: 52px;
  }
}
.cs_cs_slider_navigation_3 {
  .cs_slider_next,
  .cs_slider_prev {
    width: 90px;
    height: 70px;
    background-color: #fff;
    border-radius: 10px;
    padding: 5px;
    transition: all 0.4s ease;
    cursor: pointer;
    display: flex !important;
    position: absolute;
    top: -213px;
    @media (max-width: 1700px) {
      width: 80px;
      height: 60px;
      top: -188px;
    }
    @media (max-width: 991px) {
      display: none !important;
    }
    img {
      transition: inherit;
    }
    &:hover {
      background-color: $accent;
      img {
        filter: brightness(0) invert(1);
      }
    }
  }
  .cs_slider_next {
    right: 0;
  }
  .cs_slider_prev {
    right: 115px;
    @media (max-width: 1700px) {
      right: 100px;
    }
  }
  .slick-dots {
    display: none !important;
    @media (max-width: 991px) {
      display: flex !important;
    }
  }
}
.cs_related_doctor {
  padding-bottom: 90px;
  @media (max-width: 400px) {
    padding-bottom: 75px;
  }
  .slick-dots {
    bottom: 15px;
  }
}
.cs_gallery_grid_1 {
  display: grid;
  grid-gap: 25px;
  grid-template-columns: repeat(3, 1fr);
  @media (max-width: 991px) {
    grid-template-columns: repeat(2, 1fr);
    .cs_grid_item {
      &:nth-child(2) {
        display: none;
      }
    }
  }
  @media (max-width: 767px) {
    display: flex;
    flex-direction: column;
    gap: 25px;
  }
  .cs_section_heading.cs_style_1 {
    min-height: 263px;
    @media (max-width: 1700px) {
      min-height: 166px;
    }
    @media (max-width: 991px) {
      min-height: initial;
    }
  }
  .cs_grid_item {
    &:first-child {
      grid-column: span 2;
    }
    @media (min-width: 992px) {
      &:nth-child(6) {
        grid-column: span 2;
      }
    }

    &:nth-child(5) {
      .cs_portfolio.cs_style_1 {
        height: 767px;
        margin-top: -338px;
        @media (max-width: 1700px) {
          height: 590px;
          margin-top: -240px;
        }
        @media (max-width: 991px) {
          margin-top: 0;
          height: 400px;
        }
      }
      @media (max-width: 991px) {
        grid-column: span 2;
      }
      @media (max-width: 767px) {
        grid-column: initial;
      }
    }
  }
  @media (max-width: 1700px) {
    .cs_portfolio.cs_style_1 {
      height: 350px;
    }
  }
}
.cs_gallery_grid_2 {
  display: grid;
  grid-gap: 25px;
  grid-template-columns: repeat(3, 1fr);
  @media (max-width: 991px) {
    grid-template-columns: repeat(2, 1fr);
  }
  @media (max-width: 767px) {
    grid-template-columns: repeat(1, 1fr);
    grid-gap: 25px 0px;
  }

  .cs_grid_item {
    @media (min-width: 768px) {
      &:first-child {
        grid-column: span 2;
      }
    }

    @media (min-width: 992px) {
      &:nth-child(7) {
        grid-column: span 2;
      }
    }
  }
  @media (max-width: 1700px) {
    .cs_portfolio.cs_style_1 {
      height: 350px;
    }
  }
  @media (max-width: 420px) {
    .cs_portfolio.cs_style_1 {
      height: 300px;
    }
  }
}
.cs_portfolio.cs_style_1 .cs_portfolio_img > div > img {
  cursor: zoom-in;
  height: 100%;
  left: 0;
  -o-object-fit: cover;
  object-fit: cover;
  opacity: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 5;
}
.cs_team_grid {
  display: grid;
  grid-gap: 50px 25px;
  grid-template-columns: repeat(3, 1fr);
  @media (max-width: 991px) {
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 40px 25px;
  }
  @media (max-width: 767px) {
    grid-template-columns: repeat(1, 1fr);
    grid-gap: 40px 25px;
  }
  &.cs_list_view_wrap {
    grid-template-columns: repeat(1, 1fr);
  }
  &.cs_grid_view_wrap {
    .cs_team.cs_style_1.cs_type_2 {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }
  }
}
.cs_isotop_filter.cs_style1 {
  text-transform: capitalize;
}
.cs_pagination_box {
  .cs_pagination_item,
  .cs_pagination_arrow {
    border: none;
    &:hover {
      color: $accent;
    }
  }
  .cs_pagination_arrow {
    background-color: transparent;
  }
}
.form-check .form-check-input {
  border-color: #636363;
}
.cs_related_doctor .slick-dots {
  display: flex !important;
}
.react-datepicker-wrapper {
  display: block;
}
.cs_blog_grid_1 {
  display: grid;
  grid-gap: 20px 130px;
  grid-template-columns: repeat(4, 1fr);
  @media (max-width: 1700px) {
    grid-gap: 20px 80px;
  }
  @media (max-width: 1400px) {
    grid-gap: 20px;
  }
  @media (max-width: 991px) {
    grid-template-columns: repeat(1, 1fr);
  }
  @media (min-width: 992px) {
    > * {
      grid-column: span 2;
      &:first-child {
        grid-row: span 3;
      }
    }
  }
}
.cs_error {
  padding: 80px 0;
  min-height: 100vh;
}
.cs_nav .cs_nav_list .cs_munu_dropdown_toggle.active + ul {
  display: block;
}
input[type="date"]::-webkit-inner-spin-button,
input[type="date"]::-webkit-calendar-picker-indicator,
input[type="time"]::-webkit-inner-spin-button,
input[type="time"]::-webkit-calendar-picker-indicator {
  display: none;
  -webkit-appearance: none;
}

.cs_departments.cs_style_1 .cs_department:hover img,
.cs_iconbox.cs_style_2:hover img {
  filter: none !important;
}

.brandingWrapper {
  display: none !important;
}

.cs_nav_list .navbar-call-us {
  display: flex;
  align-items: center; /* Align the icon and text vertically */
  padding-left: 1rem;
}

.cs_nav_list .navbar-call-us img {
  margin-right: 8px; /* Space between icon and text */
}

.cs_footer.cs_style_1 .cs_text_widget + .cs_contact_widget {
  margin-top: 32px;
}

.cs_iconbox.cs_style_11 {
  height: 200px;
  display: flex;
  justify-content: center;
}

.cs_iconbox.cs_style_10 .cs_iconbox_icon {
  border: none !important;
}

.custom_background_seo {
  object-fit: contain !important;
}

// @media only screen and (max-width: 1024px) {
//   body {
//     display: flex;
//     flex-direction: column;
//     justify-content: center;
//     text-align: center;
//   }
// }

.customer-logos {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  gap: 20px;
}

.customer-logos .slide {
  flex: 1 1 calc(25% - 20px);
  max-width: calc(25% - 20px);
  display: flex;
  justify-content: center;
  align-items: center;
}

.customer-logos .slide img {
  max-width: 100%;
  height: auto;
  display: block;
}

@media (max-width: 1200px) {
  .customer-logos .slide {
    flex: 1 1 calc(33.33% - 20px);
    max-width: calc(33.33% - 20px);
  }
}

@media (max-width: 768px) {
  .customer-logos .slide {
    flex: 1 1 calc(50% - 20px);
    max-width: calc(50% - 20px);
  }
}

@media (max-width: 480px) {
  .customer-logos .slide {
    flex: 1 1 calc(100% - 20px);
    max-width: calc(100% - 20px);
  }
}

.cs_newsletter_form {
  position: relative;
}

.error-message {
  position: absolute;
  bottom: -40px;
  left: 0;
  color: red;
  font-size: 16px;
}

.success-message {
  position: absolute;
  bottom: -40px;
  left: 0;
  color: green;
  font-size: 16px;
}

.cs_form_field {
  width: 100%;
  padding-right: 100px; /* Ensure padding for button */
}

.cs_btn {
  position: absolute;
  right: 0;
  top: 0;
}

/* Ensuring the select dropdown behaves properly on mobile devices */
.cs_form_field {
  width: 100%;
  padding: 10px;
  font-size: 16px;
  border-radius: 8px;
  background-color: #f5f5f5;
  color: #333;
  border: 1px solid #ccc;
  -webkit-appearance: none; /* Remove default styling on iOS */
  -moz-appearance: none; /* Remove default styling on Firefox */
  appearance: none; /* Remove default styling on modern browsers */
}

.cs_form_field:focus {
  outline: none;
  border-color: #007bff; /* Update border color on focus */
}

/* Add a down-arrow icon to simulate a dropdown */
.cs_form_field::after {
  content: "\25BC"; /* Unicode for down-arrow */
  position: absolute;
  right: 16px;
  top: calc(50% - 10px);
  font-size: 12px;
  pointer-events: none;
}

/* Ensuring that the dropdown is positioned correctly on mobile */
select {
  -webkit-appearance: none; /* Remove default iOS appearance */
  -moz-appearance: none;
  appearance: none;
}

@media only screen and (max-width: 768px) {
  .cs_form_field {
    font-size: 14px;
    padding: 12px;
  }

  select {
    width: 100%;
  }
}

.cs_site_branding {
  width: 200px !important;
}

.cs_site_header.cs_style1 .cs_main_header_left {
  flex-wrap: nowrap !important;
  justify-content: space-between !important;
  width: 94% !important;
}

@media (max-width: 1024px) {
  .cs_site_branding {
    padding: 0.6Frem 0 2rem 0;
    width: 180px;
  }
}
.cs_hero.cs_style_1 .cs_hero_info_wrap {
  display: block !important;
}

.cs_footer_brand img {
  max-width: 60px !important;
}

.whatsapp-btn {
  position: fixed;
  bottom: 6rem;
  right: 30px;
  width: 60px;
  height: 60px;
  z-index: 1000;

  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.3s ease;
}

.whatsapp-btn img {
  width: 60px;
  height: 60px;
}

.whatsapp-btn:hover {
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.3);
  transform: scale(1.1);
}

@media (max-width: 768px) {
  .whatsapp-btn {
    position: fixed;
    bottom: 4rem;
    right: 10px;
  }

  .whatsapp-btn img {
    width: 30px;
    height: 30px;
  }
}

/* Style for the phone icon wrapper */
.phone-icon-wrapper {
  position: fixed;
  right: 3rem;
  bottom: 11rem;
  z-index: 9999;
}

/* Styles for desktop view (default) */
.phone-icon.desktop-view {
  display: block;
}

.phone-icon.desktop-view img {
  width: 60px;
  height: 60px;
}

/* Mobile view for WhatsApp button */
.phone-icon.mobile-view {
  display: none;
  background-color: #274760;
  padding: 10px 20px;
  position: fixed;
  bottom: 0;
  right: 0;
  left: 0;
  margin: 0 auto;
  width: fit-content;
  text-align: center;
}

.phone-icon.mobile-view a {
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
}

.phone-icon.mobile-view img {
  width: 40px;
  height: 40px;
  margin-right: 10px;
  border-radius: 50%;
  padding: 5px;
}

.phone-icon.mobile-view span {
  font-size: 18px;
  font-family: "Poppins", sans-serif;
  font-weight: 500;
}
/* Default to hidden */
.contact-strip {
  display: none; /* Hidden by default */
}

.brands_heading {
  font-size: 56px;
  font-weight: 800;
}

/* Show on smaller screens */
@media (max-width: 768px) {
  .contact-strip {
    background-color: #274760; /* or your preferred color */
    color: #fff;
    padding: 15px 20px;
    position: fixed;
    display: block;
    text-align: center;
    width: 100%;
    z-index: 9999;
  }

  .brands_heading {
    font-size: 42px;
  }

  .cs_site_header {
    margin-top: 50px !important;
  }

  .phone-icon.desktop-view {
    display: none; /* Hide desktop view */
  }

  .phone-icon.mobile-view {
    display: flex; /* Show mobile view */
    justify-content: center; /* Center content */
    width: 100%; /* Full width */
  }
}

/* Add any other styles as needed */
.hospitals_logo {
  height: 150px;
}
